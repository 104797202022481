<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        矽響先創科技股份有限公司
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="精準跑步感測 提升運動效率">
        <p class="image_desc">精準跑步感測 提升運動效率</p>
        <p class="image_desc">圖片為矽響先創智慧鞋墊照片</p>
        <p class="image_desc">(圖片提供：矽響先創)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          隨著健康意識的提高和運動風潮的興起，跑步作為當今社會大多人首選的運動方式，研究報告指出民眾最常從事的運動項目第二名是慢跑，每年也有大量的跑者參加馬拉松賽事，然而跑步卻有一些隱憂像是運動傷害，跑步的姿勢正確與否跟腳步姿勢有極大的關聯，故矽響先創研發智慧感測鞋墊產品，透過實證的方式檢測該產品的可行性與市場性。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          矽響先創協手三間慢跑協會一同辦理「智慧鞋墊感測器帶你邁出健康人生的腳步」實證活動，透過智慧感測鞋墊因能獲得腳部運動數據，提供使用者更精準的身體資訊，使其進行運動時能夠無憂無慮，安心享受運動樂趣。
        </div>
        <div class="content-desc">
          於112年8月23日至11月15日期間，共完成累計1.2萬筆，包含平均距離、秒數、平均速度等運動數據，完整數據留存原始資料於矽響後台系統中，並已與運動數據公益平台串接。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          透過智慧感測鞋墊結合運動健康感測平台提供客製化矯正鞋墊數據分析服務，以提供使用者更完善的身體資訊，進而實證個人化運動服務設計。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          在實證活動中收集如前腳壓力比、足跟壓力比、瞬間速度等數據，有助於偵測跑者糾正運動姿勢，並調整訓練策略，以達到最佳訓練效果，整體提升收益與品牌知名度。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11203',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-03.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
